import React from 'react'
import {Text,Heading,Flex,Box,SimpleGrid} from '@chakra-ui/react'
import {StaticImage} from 'gatsby-plugin-image'
import {Link} from 'gatsby'
import MeinSeo from '../components/seo'
//import Player from '../components/player'


const Emigration = () => {
   

    return(
        <Box pb="100px" py="3">
            <MeinSeo title="Zur Emigration deutsche Juden im 3. Reich"
            description='Dokumente zur Emigration deutscher Juden' />
        <Box mt="30">
                <Heading variant="Titel">
                    Emigration</Heading>
            
                <Text variant="zitat">

„In diesem Dorf haben wir nichts zu befürchten“
(Gertrude Joseph, Laufersweiler)
</Text>

<Text variant="solid" my="5">
Um 1900 leben etwa 1.000 Juden im heutigen Rhein-Hunsrück-Kreis. Bereits 1925 ist ihre Zahl auf nur noch 673 geschrumpft.
Bereits zu Beginn des Jahrhunderts wandern viele in die nahen Großstädte ab. Andere wagen den Schritt ins Ausland.
</Text>


              </Box> 
            <Flex  direction={['column','column','row']} > 
            {/* <Box borderLeft="2px red solid" p="2"  px="1" pb="3">  <Text p="2"  fontSize="md" fontWeight="500"  color="gray.900" >Tondokumente<br/> von Überlebenden</Text>
             &rarr; <Player url="/audio/jud1audio.mp3" />
           
             </Box> */}
             <Box borderLeft="2px red solid" p="2">
             <Link style={{padding:10}} to="/gedenkliste">
                 &rarr; Unvollständige Liste der ermordeten Juden</Link>
             </Box>
            </Flex>
        <SimpleGrid  gap={9} columns={[1,1,2]}>
            

<Box mt="5">
<StaticImage 
        src="../images/emigration/1.jpg"
        alt="Jüdischer Friedhof"
        placeholder="blurred"
        layout="constrained"
       
       />
    <Text py="3" variant="bildtext">
Eine Annonce aus dem Kirchberger Anzeiger verweist auf die Notlage der Verfolgten, die ihren Besitz weit unter Wert verkaufen.
</Text>   

<StaticImage 
        src="../images/bacharach/24.jpg"
        alt="Jüdischer Friedhof"
        placeholder="blurred"
        layout="constrained"
       
       />
<Text py="3" variant="solid">Mit Beginn der nationalsozialistischen Repression wächst die Suche nach einer sicheren Zufluchtsstätte.
</Text>
<Text py="3" variant="solid">
Durch die enge Einbindung in das soziale und wirtschaftliche Leben auf dem Lande ziehen 
  viele eine Auswanderung jedoch erst spät in Betracht. Unvorstellbar, dass sich Freunde,
   Nachbarn, Geschäftspartner gegen sie wenden könnten. 
</Text>
<StaticImage 
        src="../images/emigration/2.jpg"
        alt="Jüdischer Friedhof"
        placeholder="blurred"
        layout="constrained"
       
       />

<Text py="3" variant="bildtext">
    Simon Grünewald erhält ein Visum für die einmalige Einreise nach Frankreich: accompagné son fils – zur Begleitung seines Sohnes, der von hier aus nach Südamerika auswandert.
</Text>

<Text py="3" variant="solid">
Die Reichspogromnacht wird zur Zäsur, in deren Folge sich auch jene, die sich in Sicherheit wähnten, zum Handeln gezwungen sehen.
</Text>
<Text py="3" variant="solid">
Im Fall der Stadt Kirchberg hatten bis 1939 alle jüdischen Bewohner ihr einstiges Zuhause verlassen. Sie zogen in die Großstädte zu Verwandten, suchten die Anonymität und den Rückhalt einer jüdischen Gemeinde. Wem es gelang ein Visum zu erhalten, ging in das benachbarte Ausland. Doch mit Ausbruch des Krieges wurden sie auch dort von der nationalsozialistischen Verfolgung eingeholt.
Zum schnellen Handeln gedrängt, rissen Auswanderungspläne viele Kernfamilien auseinander. Eltern versuchten zunächst ihre Kinder in Sicherheit zu bringen, in der Hoffnung, ihnen bald folgen zu können. Briefe zeugen von den Versuchen, Normalität
vorzugeben, aber auch vom verzweifelten Warten auf die geringsten Lebenszeichen.
</Text>
<StaticImage 
        src="../images/emigration/3.jpg"
        alt="Jüdischer Friedhof"
        placeholder="blurred"
        layout="constrained"
       
       />
<Text py="3" variant="bildtext">
Gertrud Frank aus Laufersweiler und ihrem Ehemann Philipp Kohn aus Bayern gelingt 1940 die Flucht nach Shanghai, eine der letzten Zufl uchtsstätten, die kein Visum verlangt.

</Text>
</Box>
<Box>

<Text py="-3.5" variant="solid">
Augenzeugenberichte erzählen vom Zusammenbruch des Simon Grünewald aus Rheinböllen auf offener Straße, nachdem dieser den letzten seiner drei Söhne nach Südamerika verabschiedet hatte.
Während Auswanderung eine Freiwilligkeit voraussetzt, wird das Verlassen der Heimat für die Juden in Deutschland mehr und mehr alternativlos. 
Mit Beginn der nationalsozialistischen Repression wächst die Suche nach einer sicheren Zufluchtsstätte.
</Text>
<Text py="3" variant="solid">
Doch mit den steigenden Flüchtlingszahlen nimmt die Aufnahmebereitschaft der Zielländer ab. Das Ausreiseverbot vom 23.10.1941 setzt der legalen Auswanderung ein entschiedenes Ende.
Nur in wenigen Einzelfällen kehrten Überlebende aus dem Hunsrück dauerhaft nach Deutschland zurück. 
</Text>
<StaticImage 
        src="../images/emigration/4.jpg"
        alt="Jüdischer Friedhof"
        placeholder="blurred"
        layout="constrained"
       
       />
<Text py="3" variant="bildtext">
Nach der Emigration 1936 kehrt Harry Heymann 1944 mit der US-Army als „Prisoner of War Interrogator“ – zur Befragung Kriegsgefangener – in das besiegte Deutschland zurück.

</Text>
<Text py="3" variant="solid">
Soweit bekannt, begannen sie und ihre frühzeitig geflohenen Angehörigen ein neues Leben in Israel, USA, Australien, Südafrika, Schweiz, England,
Niederlande, Frankreich, Argentinien, Ecuador und Bolivien.
</Text>
<StaticImage 
        src="../images/emigration/5.jpg"
        alt="Jüdischer Friedhof"
        placeholder="blurred"
        layout="constrained"
       
       />
   
   <Text py="3" variant="bildtext">
1939 setzt der 17-jährige Leo Grünewald mit dem Dampfer „Conte Grande“ nach Südamerika über. Er ist der letzte von drei Söhnen, der die Eltern Ida und Simon
in Rheinböllen zurücklassen muss.
</Text>


            
</Box>
</SimpleGrid>
</Box>
    )

}

export default Emigration